import { InviteUserInterface } from 'components/NavigationBar/InviteModal';
import { request } from '../api';

export const inviteUsers = (
  companyKey: string,
  data: InviteUserInterface,
  handleSuccess?: Function,
  handleError?: Function
) => {
  const baseApi = process.env.REACT_APP_COVALO_API;
  const options = {
    method: 'POST',
    url: `${baseApi}/companies/${companyKey}/users/invite`,
    headers: { 'Content-Type': 'application/json' },
    data: data
  };
  const isMultiple = data?.users?.length > 1;
  const toastOptions = {
    pending: `Sending invite${isMultiple ? 's' : ''}`,
    success: `Invite${isMultiple ? 's' : ''} sent`
  };
  request(options, handleSuccess, handleError, toastOptions, true);
};

export const inviteUsersFromAnotherCompany = (
  data: InviteUserInterface,
  handleSuccess?: Function,
  handleError?: Function
) => {
  const updatedData = data.users.map(item => {
    const { firstname, lastname, ...rest } = item; // Extract the keys we want to keep
    return {
      first_name: firstname,
      last_name: lastname,
      ...rest
    };
  });
  const baseApi = process.env.REACT_APP_COVALO_API;
  const options = {
    method: 'POST',
    url: `${baseApi}/users/invite`,
    headers: { 'Content-Type': 'application/json' },
    data: { users: updatedData }
  };
  const isMultiple = data?.users?.length > 1;
  const toastOptions = {
    pending: `Sending invite${isMultiple ? 's' : ''}`,
    success: `Invite${isMultiple ? 's' : ''} sent`
  };
  request(options, handleSuccess, handleError, toastOptions, true);
};
