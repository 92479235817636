import { RefObject, forwardRef, useRef, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SingleValue } from 'react-select';
import AsyncSelect from 'react-select/async';
import {
  findAllUnRestrictedCompanyOptions,
  findCompanyOptions
} from '../../../api/services/CompaniesService';
import { loadAvailableCompanies } from 'api/services/EventsService';
import { Company } from '../../../types/Companies';
import { Option, ResponseMulti } from '../../../types/global';

const companyFilterStyle = {
  container: (provided: any) => ({
    ...provided,
    minWidth: 230
  })
};

interface CompanySelectorProps {
  onSelect: Function;
  defaultValue?: Option | string;
  isLoading?: any;
  [x: string]: any;
  withMenuCloseOnScroll: boolean;
  modalBodyRef?: RefObject<HTMLDivElement>;
  callEventEndPoint?: boolean;
  getAllCompanies?: boolean;
}
/**
 * @deprecated Use CompanySelectorMui instead
 */
const CompanySelector = forwardRef(
  (
    {
      onSelect,
      defaultValue,
      isLoading,
      withMenuCloseOnScroll,
      modalBodyRef,
      callEventEndPoint,
      getAllCompanies,
      ...props
    }: CompanySelectorProps,
    _: any //ref
  ) => {
    const { event: eventKey } = useParams();
    const [options, setOptions] = useState<SingleValue<string | Option>[]>([]);
    const [selected, setSelected] = useState<SingleValue<string | Option>>();

    const selectRef = useRef<any>(null);
    useEffect(() => {
      const handleScroll = () => {
        if (selectRef.current) {
          selectRef.current.blur();
        }
      };
      modalBodyRef?.current &&
        modalBodyRef.current.addEventListener('scroll', handleScroll);

      return () => {
        modalBodyRef?.current &&
          // eslint-disable-next-line react-hooks/exhaustive-deps
          modalBodyRef.current.removeEventListener('scroll', handleScroll);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleChange = (selectedOption: SingleValue<string | Option>) => {
      setSelected(selectedOption);
      onSelect && onSelect(selectedOption ? selectedOption : '');
    };

    const loadCompanies = (inputValue: string, callback: any) => {
      getAllCompanies
        ? findAllUnRestrictedCompanyOptions(
            inputValue,
            (response: ResponseMulti<SingleValue<Company>>) => {
              const companyOptions: any[] = [];
              response.data.content.forEach(item => {
                companyOptions.push({
                  value: item?.key,
                  label: item?.name
                });
              });
              setOptions(companyOptions);
              callback(companyOptions);
            }
          )
        : !callEventEndPoint
        ? findCompanyOptions(
            inputValue,
            0,
            (response: ResponseMulti<SingleValue<Company>>) => {
              const companyOptions: any[] = [];
              response.data.content.forEach(item => {
                companyOptions.push({
                  value: item?.key,
                  label: item?.name
                });
              });
              setOptions(companyOptions);
              callback(companyOptions);
            }
          )
        : eventKey &&
          loadAvailableCompanies(
            true,
            eventKey,
            { search: inputValue },
            (response: ResponseMulti<SingleValue<Company>>) => {
              const companyOptions: any[] = [];
              response.data.content.forEach(item => {
                companyOptions.push({
                  value: item?.key,
                  label: item?.name
                });
              });
              setOptions(companyOptions);
              callback(companyOptions);
            }
          );
    };

    useEffect(() => {
      getAllCompanies
        ? findAllUnRestrictedCompanyOptions(
            '',
            (response: ResponseMulti<SingleValue<Company>>) => {
              const companyOptions: any[] = [];
              response.data.content.forEach(item => {
                companyOptions.push({
                  value: item?.key,
                  label: item?.name
                });
              });
              setOptions(companyOptions);
            }
          )
        : !callEventEndPoint
        ? findCompanyOptions(
            '',
            0,
            (response: ResponseMulti<SingleValue<Company>>) => {
              const options: any[] = [];
              response.data.content.forEach(item => {
                options.push({
                  value: item?.key,
                  label: item?.name
                });
              });
              if (options.length === 1) {
                handleChange(options[0]);
              }
              setOptions(options);
              options?.length > 1 && isLoading?.setIsLoadingCompanies(false);
            }
          )
        : eventKey &&
          loadAvailableCompanies(
            true,
            eventKey,
            { search: '' },
            (response: ResponseMulti<SingleValue<Company>>) => {
              const options: any[] = [];
              response.data.content.forEach(item => {
                options.push({
                  value: item?.key,
                  label: item?.name
                });
              });
              if (options.length === 1) {
                handleChange(options[0]);
              }
              setOptions(options);
              options?.length > 1 && isLoading?.setIsLoadingCompanies(false);
            }
          );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (!selected) {
        if (defaultValue !== undefined) {
          if (typeof defaultValue === 'object') setSelected(defaultValue);
          else {
            const matchingOption = options.find(
              o => defaultValue === (o as SingleValue<Option>)?.value
            );
            setSelected(matchingOption);
          }
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue]);

    return (
      <AsyncSelect
        ref={selectRef}
        inputId="company-selector"
        classNamePrefix={'react-select'}
        isClearable
        value={selected}
        onChange={handleChange}
        loadOptions={loadCompanies}
        defaultOptions={options as any}
        defaultValue={defaultValue}
        cacheOptions
        isLoading={callEventEndPoint && !eventKey}
        styles={companyFilterStyle}
        placeholder="Select company"
        menuPosition={withMenuCloseOnScroll ? 'fixed' : 'absolute'}
        {...props}
      />
    );
  }
);

export default CompanySelector;
