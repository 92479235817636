import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './assets/language/translation/en.json';
import * as Sentry from '@sentry/react';

const resources = {
  en: { translation: en }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    react: {
      useSuspense: true
    }
  });

// Lazy load SkinFriendly translations
const loadSkinFriendlyTranslations = async (language: string) => {
  try {
    const skinFriendlyTranslationModule = await import(
      `./assets/language/skinFriendly/${language}.json`
    );
    const skinFriendlyTranslations = skinFriendlyTranslationModule.default;

    i18n.addResourceBundle(language, 'skinFriendly', skinFriendlyTranslations);
  } catch (_) {
    Sentry.captureMessage(
      'Something went wrong while loading the Skin Friendly translations, please fix me :(('
    );
  }
};

export { loadSkinFriendlyTranslations };
export default i18n;
