// @ts-ignore
import is from 'is_js';
import { Suspense, lazy, useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes
} from 'react-router-dom';
import { LoginPage } from 'components/pages/Login/LoginPage';
import { NotFoundPage } from 'components/pages/errors/NotFoundPage';
import allRoles, { LegacyPermission } from 'helpers/Roles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PrivateRoute from 'routes/PrivateRoute';
import PublicRoute from '../routes/PublicRoute';
import { ProductTypes } from 'api/types/ReferenceData';
import { ProductType } from 'types/Products';
import NavigationBar from 'components/NavigationBar/NavigationBar';
import { BrandPortalPermissions } from 'portals/types';
import { NavigateWithoutRouter } from 'components/common/NavigateWithoutRouter';
import Buyers from 'components/pages/Buyers/Buyers';

const BrandPortalCompanyVersionSwitch = lazy(
  () =>
    import(
      'components/pages/BrandPortal/Companies/BrandPortalCompanyVersionSwitch'
    )
);
const DataPlatform = lazy(
  () =>
    import(
      'components/pages/Homepage/Sections/ProductPages/DataPlatform/DataPlatform'
    )
);
const BrandsMarketPlace = lazy(
  () =>
    import(
      'components/pages/Homepage/Sections/ProductPages/MarketPlace/Brands/BrandsMarketPlace'
    )
);
const SuppliersMarketPlace = lazy(
  () =>
    import(
      'components/pages/Homepage/Sections/ProductPages/MarketPlace/Suppliers/SuppliersMarketPlace'
    )
);
const BrandPortal = lazy(
  () => import('components/pages/Homepage/Sections/ProductPages/Brand/Brand')
);

const UnauthorizedOnly = lazy(
  () => import('components/pages/UnauthorizedOnly/UnauthorizedOnly')
);
const SuppliersPage = lazy(
  () => import('components/pages/Suppliers/SuppliersPage')
);
const ContactPage = lazy(() => import('components/pages/contact/ContactPage'));

const BrandPortalDocumentSection = lazy(() =>
  import('components/pages/BrandPortal/Documents/Documents').then(
    ({ DocumentSection: Documents }) => ({ default: Documents })
  )
);

const BrandPortalIngredientSection = lazy(() =>
  import('components/pages/BrandPortal/Ingredients/IngredientSection').then(
    ({ IngredientSection: BrandPortalIngredientSection }) => ({
      default: BrandPortalIngredientSection
    })
  )
);

const UserAccessManagementPage = lazy(
  () =>
    import(
      'components/pages/BrandPortal/Users/UserAccessManagement/UserAccessManagementPage'
    )
);

const BrandPortalDashboard = lazy(() =>
  import('components/pages/BrandPortal/BrandPortalDashboard').then(
    ({ BrandPortalDashboard }) => ({
      default: BrandPortalDashboard
    })
  )
);
const ComingSoon = lazy(() =>
  import('supplier-portals/uxbeauty/pages/ComingSoon/ComingSoon').then(
    ({ ComingSoon }) => ({ default: ComingSoon })
  )
);
const CSMUsersDash = lazy(
  () => import('components/pages/UsersAccess/CsmUserDash/CSMUsersDashNew')
);
const AwardsDashboardPage = lazy(
  () => import('components/pages/CSM/AwardsDashboard/AwardsDashboardPage')
);

const Homepage = lazy(() => import('components/pages/Homepage/Homepage'));
const MaintenancePage = lazy(
  () => import('components/pages/Maintenance/MaintenancePage')
);
const SkinFriendlyStudy = lazy(
  () => import('components/pages/SkinFriendlyStudy/SkinFriendlyStudy')
);

const FaqsPage = lazy(() => import('components/pages/FAQs/Faqs'));
const ProductPage = lazy(() => import('components/pages/product/ProductPage'));
const CompanyPage = lazy(() => import('components/pages/company/CompanyPage'));
const Notifications = lazy(
  () => import('components/pages/Notifications/Notifications')
);
const ProjectEditor = lazy(
  () => import('components/pages/projects/ProjectEditor')
);
const Projects = lazy(() => import('components/pages/projects/Projects'));
const AnalyticsPage = lazy(
  () => import('components/pages/analytics/AnalyticsPage')
);
const ProjectView = lazy(() => import('components/pages/projects/ProjectView'));

const SearchWrapper = lazy(
  () => import('components/pages/Search/SearchWrapper')
);
const Search = lazy(() => import('components/common/Search/Search'));

const CompaniesPage = lazy(
  () => import('../components/pages/companies/Companies')
);

const CompanyEditor = lazy(
  () => import('../components/pages/companies/CompanyEditor/CompanyEditor')
);
const CompanyView = lazy(
  () => import('../components/pages/companies/CompanyView')
);
const ContactCenter = lazy(
  () => import('../components/pages/contacts/ContactCenter')
);
const ContactView = lazy(
  () => import('../components/pages/contacts/ContactView')
);
const CompanyDetails = lazy(
  () => import('../components/pages/events/CompanyDetails')
);
const EventAdmin = lazy(() => import('../components/pages/events/EventAdmin'));
const EventDetails = lazy(
  () => import('../components/pages/events/EventDetails')
);

const ProductView = lazy(
  () => import('../components/pages/products/ProductView')
);

const RegisterActivation = lazy(
  () => import('../components/pages/register/RegisterActivation')
);
const RegisterPage = lazy(
  () => import('../components/pages/register/RegisterPage')
);
const UserProfile = lazy(
  () => import('../components/pages/user/UserProfile/UserProfile')
);
const Leads = lazy(() => import('components/pages/leads/Leads'));

const PublicPagesLayout = lazy(() => import('./PublicPagesLayout'));

const ProductsEdit = lazy(
  () => import('components/pages/product/ProductsEdit')
);
const Subscriptions = lazy(
  () => import('../components/pages/Subscriptions/Subscriptions')
);
const Customer = lazy(() =>
  import(
    'components/pages/Homepage/Sections/ProductPages/Customer/Customer'
  ).then(({ Customer }) => ({ default: Customer }))
);

export const REGISTER_ROUTE = '/register';
const skinFriendlyLanguages = ['eng-uk', 'eng-us', 'ger', 'ita', 'fra', 'esp'];

const Layout = () => {
  const auth = useAuth();

  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

  const { maintenanceMode: isMaintenance, brandPortalDocuments } = useFlags();
  if (isMaintenance) {
    return <MaintenancePage />;
  }

  return (
    <>
      <Router basename={process.env.PUBLIC_URL}>
        <Suspense
          fallback={
            <div className="px-3">
              <NavigationBar />
            </div>
          }
        >
          <Routes>
            <Route
              path="/"
              element={
                <PublicRoute
                  component={<Homepage />}
                  hasWhiteBackground
                  noVerticalPadding
                  disableTopMargin
                  noHorizontalPadding
                />
              }
              caseSensitive
            />
            <Route
              path="/maintenance"
              element={<MaintenancePage />}
              caseSensitive
            />
            <Route path="/login" element={<LoginPage />} />
            <>
              {skinFriendlyLanguages.map(language => (
                <Route
                  key={language}
                  path={`/study/skin-friendly-cities-${language}`}
                  element={<PublicRoute component={<SkinFriendlyStudy />} />}
                  caseSensitive
                />
              ))}
            </>
            <Route
              path="ourSolution"
              element={<NavigateWithoutRouter to="/notFound" />}
            />
            {ProductTypes.map(productType => (
              <Route
                key={productType.label}
                path={`/admin/products/${productType.value.toLowerCase()}`}
                element={
                  <PrivateRoute
                    component={<ProductsEdit productType={productType.value} />}
                    allowedUsers={allRoles.EDIT_PRODUCTS}
                    preventDoubleScrollbars
                    noVerticalPadding
                  />
                }
              />
            ))}
            <Route
              path="/marketplace/brands"
              element={
                <PublicRoute
                  hasWhiteBackground
                  noVerticalPadding
                  disableTopMargin
                  noHorizontalPadding
                  component={<BrandsMarketPlace />}
                />
              }
            />
            <Route
              path="/marketplace/suppliers"
              element={
                <PublicRoute
                  hasWhiteBackground
                  noVerticalPadding
                  disableTopMargin
                  noHorizontalPadding
                  component={<SuppliersMarketPlace />}
                />
              }
            />
            <Route
              path="/brandPortal"
              element={
                <PublicRoute
                  hasWhiteBackground
                  noVerticalPadding
                  disableTopMargin
                  noHorizontalPadding
                  component={<BrandPortal />}
                />
              }
            />
            <Route
              path="/dataPlatform"
              element={
                <PublicRoute
                  hasWhiteBackground
                  noVerticalPadding
                  disableTopMargin
                  noHorizontalPadding
                  component={<DataPlatform />}
                />
              }
            />
            <Route
              path="/customerPortal"
              element={
                <PublicRoute
                  hasWhiteBackground
                  noVerticalPadding
                  disableTopMargin
                  noHorizontalPadding
                  component={<Customer />}
                />
              }
            />
            <Route
              path="/notFound"
              element={<PublicRoute component={<NotFoundPage />} />}
            />
            <Route path="/about" element={<Navigate to="/" />} caseSensitive />
            <Route
              path="/buyers"
              element={<PublicRoute component={<Buyers />} />}
              caseSensitive
            />
            <Route
              path="/suppliers"
              element={<PublicRoute component={<SuppliersPage />} />}
              caseSensitive
            />
            {/* 
            navigate works as redirect
            //https://stackabuse.com/redirects-in-react-router/ */}
            <Route
              path="/subscriptions/overview"
              element={<Navigate to="/register" />}
              caseSensitive
            />
            <Route path="errors/404" element={<Navigate to="/" />} />
            <Route path="errors/500" element={<Navigate to="/" />} />
            <Route
              path="errors/unauthorized-only"
              element={<UnauthorizedOnly />}
            />
            <Route element={<PublicPagesLayout />}>
              <Route
                path="/ingredients/permalink/:productKey"
                element={<ProductPage type={ProductType.INGREDIENTS} />}
              />
              <Route
                path="/formulations/permalink/:productKey"
                element={<ProductPage type={ProductType.FORMULATIONS} />}
              />
              <Route
                path="/services/permalink/:productKey"
                element={<ProductPage type={ProductType.SERVICES} />}
              />
              <Route path="/contact" element={<ContactPage />} />
            </Route>
            <Route
              path="/company/:companyKey"
              element={
                <PublicRoute
                  component={<CompanyPage />}
                  hasWhiteBackground
                  disableTopMargin
                />
              }
            />
            <Route
              path="/events/:event/company/:companyKey"
              element={
                <PrivateRoute
                  component={<CompanyPage />}
                  allowedUsers={allRoles.ALL}
                  hasWhiteBackground
                />
              }
            />
            {/* Public Routes */}
            <Route
              path={REGISTER_ROUTE}
              element={
                <PublicRoute
                  noVerticalPadding
                  component={<RegisterPage />}
                  preventDoubleScrollbars
                  unauthorizedOnly={auth.isAuthenticated}
                />
              }
              caseSensitive
            />
            <Route
              path={`${REGISTER_ROUTE}/:key`}
              element={
                <PublicRoute
                  noVerticalPadding
                  component={<RegisterPage />}
                  preventDoubleScrollbars
                  unauthorizedOnly={auth.isAuthenticated}
                />
              }
              caseSensitive
            />
            <Route
              path={`${REGISTER_ROUTE}/activate`}
              element={
                <PublicRoute
                  component={<RegisterActivation />}
                  unauthorizedOnly={auth.isAuthenticated}
                />
              }
              caseSensitive
            />
            <Route
              path={`${REGISTER_ROUTE}/visitor`}
              element={<RegisterPage />}
              caseSensitive
            />
            <Route
              path="/faqs"
              element={<PublicRoute component={<FaqsPage />} />}
              caseSensitive
            />
            <Route
              path="/ingredients/search"
              element={
                <PublicRoute
                  preventDoubleScrollbars
                  component={
                    <SearchWrapper
                      key="INGREDIENTS"
                      target={ProductType.INGREDIENTS}
                    />
                  }
                />
              }
            />
            <Route
              path="/formulations/search"
              element={
                <PublicRoute
                  preventDoubleScrollbars
                  component={
                    <SearchWrapper
                      key="FORMULATIONS"
                      target={ProductType.FORMULATIONS}
                    />
                  }
                />
              }
            />
            <Route
              path="/services/search"
              element={
                <PublicRoute
                  preventDoubleScrollbars
                  component={
                    <SearchWrapper
                      key="SERVICES"
                      target={ProductType.SERVICES}
                    />
                  }
                />
              }
            />
            {/* Private Routes */}
            {/* csm pages routes */}
            <Route
              path="/admin/subscriptions"
              element={
                <PrivateRoute
                  component={<Subscriptions />}
                  allowedUsers={allRoles.SUPPORT_ROLES}
                  preventDoubleScrollbars
                />
              }
            />
            <Route
              path="/admin/csm/awardsDash"
              element={
                <PrivateRoute
                  component={<AwardsDashboardPage />}
                  allowedUsers={allRoles.SUPPORT_ROLES}
                />
              }
            />
            <Route
              path="/admin/csm/users"
              element={
                <PrivateRoute
                  component={<CSMUsersDash />}
                  noVerticalPadding
                  preventDoubleScrollbars
                  allowedUsers={allRoles.SUPPORT_ROLES}
                />
              }
            />
            {/* //end of csm pages routes */}
            {/* //Brand Portal routes */}
            <Route
              path="/events/:event/product/:productKey"
              element={
                <PrivateRoute
                  component={<ProductPage />}
                  brandPortalPermissions={[BrandPortalPermissions.SEARCH]}
                />
              }
            />
            <Route
              path="/admin/events/:key/companies/:companyKey"
              element={
                <PrivateRoute
                  brandPortalPermissions={[BrandPortalPermissions.SEARCH]}
                  component={<CompanyDetails />}
                />
              }
            />
            <Route
              path="/admin/event/:event/dashboard"
              element={
                <PrivateRoute
                  component={<BrandPortalDashboard />}
                  brandPortalPermissions={[
                    BrandPortalPermissions.COMPANY_MANAGEMENT,
                    BrandPortalPermissions.PRODUCT_MANAGEMENT,
                    BrandPortalPermissions.DOCUMENT_MANAGEMENT,
                    BrandPortalPermissions.USER_MANAGEMENT
                  ]}
                />
              }
            />
            <Route
              path="/admin/event/:event/companies"
              element={
                <PrivateRoute
                  preventDoubleScrollbars
                  hasWhiteBackground
                  brandPortalPermissions={[
                    BrandPortalPermissions.COMPANY_MANAGEMENT
                  ]}
                  component={<BrandPortalCompanyVersionSwitch />}
                />
              }
            />
            <Route
              path="/admin/event/:event/ingredients"
              element={
                <PrivateRoute
                  preventDoubleScrollbars
                  hasWhiteBackground
                  brandPortalPermissions={[
                    BrandPortalPermissions.PRODUCT_MANAGEMENT
                  ]}
                  component={<BrandPortalIngredientSection />}
                />
              }
            />
            <Route
              path="/admin/event/:event/documents"
              element={
                <PrivateRoute
                  preventDoubleScrollbars
                  hasWhiteBackground
                  brandPortalPermissions={[
                    BrandPortalPermissions.DOCUMENT_MANAGEMENT
                  ]}
                  component={
                    brandPortalDocuments ? (
                      <BrandPortalDocumentSection />
                    ) : (
                      <ComingSoon />
                    )
                  }
                />
              }
            />
            <Route
              path="/admin/event/:event/users"
              element={
                <PrivateRoute
                  preventDoubleScrollbars
                  hasWhiteBackground
                  brandPortalPermissions={[
                    BrandPortalPermissions.USER_MANAGEMENT
                  ]}
                  component={<UserAccessManagementPage />}
                />
              }
            />
            <Route
              path="/search/ingredients/:event"
              element={
                <PrivateRoute
                  brandPortalPermissions={[BrandPortalPermissions.SEARCH]}
                  preventDoubleScrollbars
                  component={<Search target={ProductType.INGREDIENTS} />}
                />
              }
            />
            {/* //end of brand portal routes */}
            <Route
              path="/analytics"
              element={
                <PrivateRoute
                  component={<AnalyticsPage />}
                  allowedUsers={[
                    ...allRoles.SUPPORT_ROLES,
                    ...allRoles.SUPPLIER_ROLES
                  ]}
                  legacyPermissions={[LegacyPermission.ANALYTICS_SHOW]}
                />
              }
            />
            <Route
              path="/user/profile"
              element={
                <PrivateRoute
                  component={<UserProfile />}
                  allowedUsers={allRoles.ALL_AND_EXT}
                />
              }
            />
            <Route
              path="/search"
              element={
                <PrivateRoute
                  preventDoubleScrollbars
                  component={<SearchWrapper target={ProductType.INGREDIENTS} />}
                  allowedUsers={allRoles.SUPPORT_ROLES}
                />
              }
            />
            <Route
              path="/admin/product/:type/:company/:segment/:id"
              element={
                <PrivateRoute
                  component={<ProductView editing />}
                  allowedUsers={allRoles.PRODUCTS}
                />
              }
            />
            <Route
              path="/admin/product/:type/:company/:segment/editor/new"
              element={
                <PrivateRoute
                  component={<ProductView editing={false} />}
                  allowedUsers={allRoles.EDIT_PRODUCTS}
                />
              }
            />
            <Route
              path="/events"
              element={
                <PrivateRoute
                  component={<EventAdmin key={'EVENT_ADMIN'} />}
                  allowedUsers={allRoles.SUPPORT_ROLES}
                />
              }
            />
            <Route
              path="/events/editor/:key"
              element={
                <PrivateRoute
                  component={<EventDetails />}
                  allowedUsers={allRoles.SUPPORT_ROLES}
                />
              }
            />
            <Route
              path="/admin/events"
              element={
                <PrivateRoute
                  component={<EventAdmin key={'SUPPLIER'} />}
                  allowedUsers={allRoles.SUPPORT_ROLES}
                />
              }
            />
            <Route
              path="/admin/companies"
              element={
                <PrivateRoute
                  component={<CompaniesPage />}
                  allowedUsers={allRoles.EDIT_COMPANY}
                />
              }
            />
            <Route
              path="/admin/companies/editor/:key"
              element={
                <PrivateRoute
                  component={<CompanyView />}
                  noVerticalPadding
                  preventDoubleScrollbars
                  allowedUsers={allRoles.EDIT_COMPANY}
                />
              }
            />
            <Route
              path="/admin/companies/editor/"
              element={
                <PrivateRoute
                  // @ts-ignore
                  component={<CompanyEditor />}
                  allowedUsers={allRoles.CREATE_COMPANY}
                />
              }
            />
            <Route
              path="/leads"
              element={
                <PrivateRoute
                  component={<Leads />}
                  allowedUsers={[
                    ...allRoles.SUPPORT_ROLES,
                    'supplier-manager',
                    'supplier-manager-unverified'
                  ]}
                  legacyPermissions={[LegacyPermission.LEADS_SHOW]}
                />
              }
            />
            <Route
              path="/projects"
              element={
                <PrivateRoute
                  component={<Projects />}
                  allowedUsers={allRoles.PROJECT_SPACE}
                  excludeRoles={allRoles.SUPPLIER_ROLES}
                />
              }
            />
            <Route
              path="/projects/editor"
              element={
                <PrivateRoute
                  // @ts-ignore
                  component={<ProjectEditor />}
                  allowedUsers={allRoles.PROJECT_SPACE}
                  excludeRoles={allRoles.SUPPLIER_ROLES}
                />
              }
            />
            <Route
              path="/project/:company/:key"
              element={
                <PrivateRoute
                  component={<ProjectView />}
                  allowedUsers={allRoles.PROJECT_SPACE}
                  excludeRoles={allRoles.SUPPLIER_ROLES}
                />
              }
            />
            <Route
              path="/contacts"
              element={
                <PrivateRoute
                  component={<ContactCenter />}
                  allowedUsers={allRoles.ALL}
                />
              }
            />
            <Route
              path="/contacts/:key"
              element={
                <PrivateRoute
                  component={<ContactView />}
                  allowedUsers={allRoles.ALL}
                />
              }
            />
            <Route
              path="/notifications"
              element={
                <PrivateRoute
                  component={<Notifications />}
                  allowedUsers={allRoles.ALL_AND_EXT}
                />
              }
            />
            <Route path="*" element={<Navigate to="/errors/404" replace />} />
          </Routes>
        </Suspense>
      </Router>
    </>
  );
};

export default Layout;
