/*
 * NOTE:
 * All this file is from the legacy covalo app,
 * its a copy with small adjustments to comply
 * with the eslint rules we have
 */

function getObjectFromQueryString(
  queryString: string,
  keyTransform: (key: string) => string = (key: string) => key.toLowerCase(),
  keyFilter?: (key: string) => boolean
): Record<string, Primitive | Primitive[]> {
  const obj: Record<string, Primitive | Primitive[]> = {};
  if (!queryString) {
    return obj;
  }
  const query =
    queryString.indexOf('?') === 0 ? queryString.substring(1) : queryString;
  const vars = query.split('&');

  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    let key = pair[0];
    let value = pair[1];
    key = keyTransform(decodeURIComponent(key));
    value = decodeURIComponent(value);

    if (keyFilter && !keyFilter(key)) {
      continue;
    }

    // if (splitMultipleValues) {
    //   const values = value.split(defaultQueryDelimiter).map(valueTransform);
    //   if (!valueFilter || values.every(valueFilter)) {
    //     obj[key] = values;
    //   }
    // } else {
    //   value = valueTransform(value);
    //   if (!valueFilter || valueFilter(value)) {
    //     obj[key] = value;
    //   }
    // }
  }

  return obj;
}

export declare type Primitive =
  | string
  | number
  | bigint
  | boolean
  | symbol
  | null
  | undefined;
let windowProxy: any = {
  location: {
    pathname: '/',
    host: '',
    hostname: '',
    href: '',
    search: '',
    origin: ''
  },
  screen: {
    width: ''
  },
  innerwidth: 100,
  orientation: 'portrait',
  history: {
    pushState() {
      // do nothing
    }
  },
  APPLICATION_ENVIRONMENT: 'local'
};

let documentProxy: any = {
  title: 'Covalo',
  activeElement: {
    blur() {
      // do nothing
    }
  },

  getElementsByTagName() {
    return [];
  },

  getElementById(id: string) {
    return this[id];
  },

  getElementsByClassName() {
    return [];
  },

  addEventListener() {
    // do nothing
  },

  createElement() {
    return {};
  }
};

let navigatorProxy = {
  userAgent: ''
};

if (typeof window !== 'undefined') {
  windowProxy = window;
}

if (typeof document !== 'undefined') {
  documentProxy = document;
}

if (typeof navigator !== 'undefined') {
  navigatorProxy = navigator;
}

export default {
  getLocationPathname() {
    return windowProxy.location.pathname;
  },

  getLocationHost() {
    return windowProxy.location.host;
  },

  getLocationHostname() {
    return windowProxy.location.hostname;
  },

  getLocationHref() {
    return windowProxy.location.href;
  },

  parseBoolean(value: string): any {
    if (value === 'true') {
      return true;
    }
    if (value === 'false') {
      return false;
    }
    return value;
  },

  /**
   * Parses the browser's location query string and returns it in object form.
   *
   * @param keyTransform which function should be applied on each of the keys
   * @param valueTransform which function should be applied on each of the values
   * @param keyFilter will remove all key/value pairs which DON'T match this condition on the key AFTER transformation
   * @param valueFilter will remove all key/value pairs which DON'T match this condition on the value(s) AFTER transformation
   * @param splitMultipleValues if set to true, it will split up the values by the {@link defaultQueryDelimiter} and return an array of values instead (defaults to false)
   * @returns {@code Record<string, Primitive>} if {@code splitMultipleValues} is false, {@code Record<string, Primitive[]>} if {@code splitMultipleValues} is true
   */
  getLocationQueryObject(
    keyTransform: (key: string) => string = (key: string) => key,
    keyFilter?: (key: string) => boolean
  ): Record<string, Primitive | Primitive[]> {
    return getObjectFromQueryString(
      this.getLocationQueryString(),
      keyTransform,
      keyFilter
    );
  },

  getLocationQueryString() {
    return windowProxy.location.search;
  },

  getLocationOrigin() {
    return windowProxy.location.origin;
  },

  getScreenWidth() {
    return windowProxy.screen.width;
  },

  getWindowInnerWidth() {
    return windowProxy.innerWidth;
  },

  getGlobal(key: string) {
    return windowProxy[key];
  },

  getGlobalStore() {
    return windowProxy;
  },

  setGlobal(key: string, object: any) {
    windowProxy[key] = object;
    return object;
  },

  getActiveEnvironment() {
    const appEnv = this.getGlobal('APPLICATION_ENVIRONMENT');
    return appEnv ? appEnv : 'local';
  },

  sendRedirect(location: string) {
    windowProxy.location = location;
  },

  isTouchDevice() {
    return (
      typeof windowProxy.orientation !== 'undefined' ||
      navigatorProxy.userAgent.indexOf('IEMobile') !== -1
    );
  },

  setAddressbarUrl(url: string) {
    if (this.getLocationPathname() !== url) {
      windowProxy.history.pushState({}, documentProxy.title, url);
    }
  },

  isBrowser() {
    return typeof window !== 'undefined';
  },

  document() {
    return documentProxy;
  }
};
