import {
  IS_UXBEAUTY,
  WHITELABEL_EVENT_KEY
} from 'components/constants/Constants';
import { ErrorHandler, SuccessHandler, request } from '../apiTS';
import { SearchState } from 'types/Table';
import { MRT_ColumnFiltersState } from 'material-react-table';
import { makeColumnFiltersParameters, makeSortParameters } from './helper';
import { useAxiosWithAuth } from './useAxios';
import { PortalTypes, UserSettings } from 'portals/types';

export const baseApiLegacy = process.env.REACT_APP_COVALO_LEGACY_API;
export const baseApi = process.env.REACT_APP_COVALO_API;

export const moveUsersToCompany = (
  users: string[],
  company: string,
  move: boolean,
  success: SuccessHandler,
  handleError: ErrorHandler
) => {
  const data = {
    users: users,
    company: company,
    move: move
  };
  const options = {
    method: 'POST',
    url: `${baseApi}/users/assign`,
    headers: { 'Content-Type': 'application/json' },
    data: data
  };

  request(options, success, handleError);
};

export const getUserEventProfile = (
  eventKey: string,
  success: SuccessHandler,
  handleError: ErrorHandler
) => {
  const options = {
    method: 'GET',
    url: `${baseApi}/events/${eventKey}/users/profile`,
    headers: { 'Content-Type': 'application/json' }
  };

  request(options, success, handleError);
};

export const loadUserSettings = (
  success: SuccessHandler<UserSettings>,
  handleError?: ErrorHandler
) => {
  const options = {
    method: 'GET',
    url: `${baseApi}/users/settings`,
    params: IS_UXBEAUTY
      ? {
          entity_type: 'EVENT',
          entity_id: WHITELABEL_EVENT_KEY
        }
      : undefined,
    headers: { 'Content-Type': 'application/json' }
  };

  request(options, success, handleError);
};

export enum CovaloUserType {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING'
}

export const getAllUsers = (
  tab: CovaloUserType,
  searchState: SearchState & { columnFilters: MRT_ColumnFiltersState },
  handleSuccess: SuccessHandler,
  handleError: ErrorHandler
) => {
  const url = `${baseApi}/users${
    searchState.sort.length ? '?' : ''
  }${makeSortParameters(searchState.sort)}${
    searchState.columnFilters.length
      ? searchState.sort.length
        ? '&'
        : '?'
      : ''
  }${makeColumnFiltersParameters(searchState.columnFilters)}`;
  const params = {
    type: tab.toLowerCase(),
    size: searchState.size,
    page: searchState.page,
    ...(searchState.query
      ? {
          operator: 'OR',
          email__icontains: searchState.query,
          firstname__icontains: searchState.query,
          username__icontains: searchState.query,
          source__icontains: searchState.query,
          company__icontains: searchState.query
        }
      : {})
  };

  const options = {
    method: 'GET',
    url: url,
    params,
    headers: { 'Content-Type': 'application/json' }
  };

  request(options, handleSuccess, handleError, undefined, undefined);
};

export const resendConfirmationEmailToUser = (
  username: string,
  companyKey: string,
  success: SuccessHandler,
  handleError: ErrorHandler
) => {
  const url = `${baseApi}/users/resend/verification`;
  const params = new URLSearchParams({
    username: username,
    company: companyKey
  });

  const options = {
    method: 'POST',
    params,
    url,
    headers: { 'Content-Type': 'application/json' }
  };

  request(options, success, handleError);
};
//user context services

export const useGetLegacySettings = () =>
  useAxiosWithAuth<{
    userId: string;
    department: string;
    permissions: string[];
  }>(
    {
      url: `${baseApiLegacy}/users/settings`,
      method: 'GET'
    },
    { manual: true }
  );

export const useGetUserSettings = () =>
  useAxiosWithAuth<UserSettings>(
    {
      url: `${baseApi}/users/settings`,
      params: IS_UXBEAUTY
        ? {
            entity_type: 'EVENT',
            entity_id: WHITELABEL_EVENT_KEY
          }
        : undefined,
      method: 'GET'
    },
    { manual: true }
  );

export const useGetAllEvents = () =>
  useAxiosWithAuth<{
    content: { key: string; name: string; type: PortalTypes }[];
  }>(
    {
      url: `${baseApi}/events`,
      params: { size: 100 },
      method: 'GET'
    },
    { manual: true }
  );

export const useGetAllCompanies = () =>
  useAxiosWithAuth<{
    content: { key: string; name: string }[];
    page: { total_pages: number };
  }>(
    {
      url: `${baseApi}/companies`,
      method: 'GET'
    },
    { manual: true }
  );

export const useGetLanguages = (page: number, search: string) =>
  useAxiosWithAuth<{
    content: { name: string; key: string; eventKey?: string }[];
  }>(
    {
      url: `${baseApi}/reference/data/languages`,
      params: {
        page,
        name__icontains: search
      },
      method: 'GET'
    },
    { manual: true }
  );
